import React, { useEffect, useState } from "react";
import { Button, Input } from "../components/ui";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";
import axios from "axios";

const api_key = "7197b5ccffmsha7d216dc82466e8p163e9ajsn5e2b2c6b527b";
const prompt = "flower";
const pollPeriod = 20000; // 20 seconds

const Home = () => {
    const currentYear = new Date().getFullYear();
    const inputDataStructure = {
        query: {
            key: "query",
            label: "",
            data: "",
            type: "text",
            isValid: true,
            error: "",
            placeHolder: "Enter your query",
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const [jobId, setJobId] = useState(null);
    const [status, setStatus] = useState("Pending");
    const [progress, setProgress] = useState(0);
    const [imageUrl, setImageUrl] = useState(null);
    const [error, setError] = useState(null);

    const handleDownloadClick = () => {
        if (imageUrl) {
            // Split the image URL to get the file name
            const fileName = imageUrl.split("/").pop();
            //setImageFileName(fileName);

            // Create a temporary anchor element
            const link = document.createElement("a");
            link.href = imageUrl;
            link.download = fileName;

            // Programmatically click on the anchor to initiate the download
            link.click();
        }
    };

    const postJob = async () => {
        try {
            const response = await axios.post(
                "https://midjourney4.p.rapidapi.com/rapida/sets/fast",
                {
                    prompt: inputs.query.data,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        "X-RapidAPI-Key": api_key,
                        "X-RapidAPI-Host": "midjourney4.p.rapidapi.com",
                    },
                }
            );
            setJobId(response.data);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleClick = () => {
        if (!jobId) {
            postJob();
        }
    };

    useEffect(() => {
        const checkStatus = async () => {
            try {
                const response = await axios.get(
                    `https://midjourney4.p.rapidapi.com/rapida/sets?track_id=${jobId}`,
                    {
                        headers: {
                            "X-RapidAPI-Key": api_key,
                            "X-RapidAPI-Host": "midjourney4.p.rapidapi.com",
                        },
                    }
                );
                const data = response.data;
                setStatus(data.outcome);
                setProgress(data.percent);
                console.log(data.percent);

                if (data.url) {
                    console.log(data.url);
                    setImageUrl(data.url);
                    setJobId(null);
                }

                if (data.error || data.outcome === "Failure") {
                    setError(data.error);
                }
            } catch (error) {
                setError(error.message);
            }
        };

        if (jobId && status !== "Completed" && !error) {
            const interval = setInterval(checkStatus, pollPeriod);

            return () => {
                clearInterval(interval);
            };
        }
    }, [jobId, status, error]);

    const generateUUID = () => {
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                const r = (Math.random() * 16) | 0,
                    v = c === "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
        return uuid;
    };

    const handleChange = (data, input) => {
        input.data = data;

        let input_list = { ...inputs };
        input_list[input.key] = input;
        setInputs(input_list);
    };
    return (
        <div id="body" className="min-h-screen">
            <div id="content" className="min-h-screen flex flex-col  relative">
                <div className="grid-container"></div>
                <header className="flex justify-between items-center text-white p-4">
                    <div className="flex items-center"></div>
                    <div className="flex gap-4">
                        {/* <Button
                            className={"bg-[#761bd7] text-white"}
                            text="Login"
                        />
                        <Button
                            className={"bg-[#761bd7] text-white"}
                            text="Register"
                        /> */}
                    </div>
                </header>
                {jobId != null && (
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            zIndex: 9999,
                        }}
                    >
                        <FaSpinner
                            className="text-white animate-spin"
                            size={48}
                        />
                    </div>
                )}
                <main className="flex-1 flex flex-col items-center justify-center w-full">
                    {/* <img
                        src={require("../assets/img/logo.png")}
                        className="w-[300px] mb-4"
                    /> */}

                    <h1 className="text-white font-extrabold text-5xl mt-4">
                        Pro scribe AI Image Generator
                    </h1>
                    <h3 className="text-slate-300 text-center text-2xl mt-4 mx-auto w-[70%]">
                        Pro scribe's free AI image generator lets you quicly
                        turn your words into oh-so-incredible images.
                        <br />
                        Start bringing your ideas to life!
                    </h3>
                    <div className="grid grid-cols-4 gap-2 w-[70%] mt-6">
                        <div className="col-span-3">
                            <Input
                                input={inputs.query}
                                handleChange={handleChange}
                            />
                        </div>
                        <div>
                            <Button
                                className={"bg-[#761bd7] text-white"}
                                text="Generate"
                                handleClick={handleClick}
                            />
                        </div>
                    </div>
                    <h3 className="text-slate-300 text-center text-2xl mt-4 mx-auto w-[70%]">
                        Need inspiration? Try these: A photo of a magical forest
                        city from the future with flying cars and robotic life.
                    </h3>
                    {imageUrl && (
                        <div className="flex flex-col items-center justify-center">
                            <img
                                className="w-96 h-96  object-contain"
                                src={imageUrl}
                                alt="Image"
                            />
                            <Button
                                className="mt-4 bg-[#761bd7] text-white"
                                text="Download"
                                handleClick={handleDownloadClick}
                            />
                        </div>
                    )}
                </main>

                <footer className="text-white p-4 text-center flex justify-between">
                    <div className="flex justify-center items-center">
                        &copy; {currentYear} AI Image generator
                    </div>
                    <a
                        href="instagram.com/fany.app"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white"
                    >
                        <AiOutlineInstagram size={32} />
                    </a>
                </footer>
            </div>
        </div>
    );
};

export default Home;
